<template>
  <el-dialog
    v-bind="{title:'新增配置',width:'420px'}"
    v-dialogDrag
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="100px" :model="submitForm" :rules="rules">
      <el-form-item :label="materialTypes.title" prop="mp.type">
        <el-cascader
          clearable
          v-model="submitForm.mp.type"
          :options="materialTypes.options"
          :props="{ value: 'dictValue', label: 'dictName', checkStrictly: true, emitPath: false }"
          filterable
          class="w-250"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="地区" prop="id">
        <RegionCascader ref="regionCascader" class="w-250"></RegionCascader>
      </el-form-item>
      <el-form-item label="年月" prop="year">
        <el-date-picker
          v-model="date"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月"
          class="w-250"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="基准值" prop="mp.standard">
        <el-input-number
          v-model="submitForm.mp.standard"
          :min="60"
          :max="140"
          placeholder="请输入基准值，范围在60到140之间"
          :controls="false"
          class="w-250"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { editStandard } from '@/apis/analysis/priceStandardConfig'
import RegionCascader from '@/components/Business/RegionCascader'
import dictionary from '@/dictionary'

export default {
  components: {
    RegionCascader
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    },
    isAddSub () {
      return this.editData?.isAddSub
    },
    date: {
      get () {
        const submitForm = this.submitForm
        return submitForm.year ? submitForm.year + '-' + String(submitForm.month).padStart(2, 0) : ''
      },
      set (value) {
        if (value) {
          const [year, month] = value.split('-')
          this.submitForm.year = parseInt(year)
          this.submitForm.month = parseInt(month)
        }
      }
    }
  },
  data () {
    return {
      materialTypes: dictionary.data_material_type,
      loading: false,
      submitForm: {
        id: '',
        level: '',
        year: '',
        month: '',
        mp: {
          standard: undefined, // 基准值
          type: '' // 材料类型
        }
      },
      rules: {
        'mp.type': { required: true, message: '请选择' },
        id: { required: true, message: '请选择', trigger: 'submit' },
        year: { required: true, message: '请选择' },
        'mp.standard': { required: true, message: '请选择' }
      }
    }
  },
  methods: {
    submit () {
      const areaNode = this.$refs.regionCascader.getCheckedNodes()[0]
      if (areaNode) {
        const areas = { 1: 'PROVINCE', 2: 'CITY', 3: 'DISTRICT' }
        this.submitForm.id = areaNode.value
        this.submitForm.level = areas[areaNode.level]
      }

      const submitForm = { ...this.submitForm }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          editStandard(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$refs.form.resetFields()
      this.$refs.regionCascader.setValue([])
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number.is-without-controls .el-input__inner {
 text-align: left;
}
</style>
