<template>
  <PageContainer>
    <BaseTable
      id="priceStandardConfig"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addVisible=true">新 增</el-button>
      </template>
      <vxe-column type="expand" align="center" width="80">
        <template #content="{row: {mps}, row: data,  rowIndex}">
          <BaseTable
            :toolsbar="false"
            :ref="'tableRef'+rowIndex"
            :data="mps"
            :keep-source="true"
            :edit-config="{trigger: 'manual', mode: 'row', autoClear: false, }"
          >
            <vxe-column width="50"></vxe-column>
            <vxe-column field="typeName" title="材料名称"></vxe-column>
            <vxe-column field="type" title="材料类型"></vxe-column>
            <vxe-column field="standard" title="基准值" :edit-render="{}">
              <template #edit="{ row }">
                <vxe-input
                  v-model.number="row.standard"
                  type="number"
                  :min="60"
                  :max="140"
                  placeholder="请输入基准值，范围在60到140之间"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column title="操作" :show-overflow="false" width="180">
              <template #default="{ row }">
                <template v-if="$refs['tableRef' + rowIndex].isActiveByRow(row)">
                  <el-button type="primary" @click="saveRowEvent(row, data)">保存</el-button>
                  <el-button @click="cancelRowEvent(row, rowIndex)">取消</el-button>
                </template>
                <template v-else>
                  <el-button type="primary" @click="editRowEvent(row, rowIndex)">编辑</el-button>
                </template>
              </template>
            </vxe-column>
          </BaseTable>
        </template>
      </vxe-column>
      <vxe-column field="areaName" title="地区"></vxe-column>
      <vxe-column field="id" title="地区ID"></vxe-column>
      <vxe-column field="level" title="地区级别"></vxe-column>
      <vxe-column field="year" title="年月">
        <template v-slot="{row}">
          {{row.year}}-{{row.month}}
        </template>
      </vxe-column>
      <vxe-column title="操作" :show-overflow="false" width="180">
        <template #default="{ row }">
          <el-button type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>
    <div class="pt-15 bg-white"></div>
    <Add :visible.sync="addVisible" @refresh="initList"></Add>
  </PageContainer>
</template>

<script>
import {
  getStandardList,
  editStandard,
  delStandard
} from '@/apis/analysis/priceStandardConfig'
import Add from './Add'
export default {
  components: {
    Add
  },
  created () {
    this.initList()
  },
  data () {
    return {
      loading: false,
      tableData: [],
      addVisible: false
    }
  },
  methods: {
    handleDelete (row) {
      this.$confirm('是否删除该地区所有材料的基准值?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delStandard({ id: row.id }).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    editRowEvent (row, rowIndex) {
      this.$refs['tableRef' + rowIndex].setActiveRow(row)
    },
    async saveRowEvent (row, data) {
      const res = await editStandard({
        id: data.id,
        level: data.level,
        year: data.year,
        month: data.month,
        mp: {
          standard: row.standard,
          type: row.type
        }
      })
      if (res.code == 200) {
        this.$notify.success({ title: '成功', message: '保存成功！' })
      } else {
        this.$notify.error({ title: '失败', message: res.msg })
      }
      this.initList()
    },
    cancelRowEvent (row, rowIndex) {
      const $table = this.$refs['tableRef' + rowIndex]
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    },
    // 获取明细
    async initList () {
      this.loading = true
      const res = await getStandardList()
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
