import http from '@/utils/http'

export function getStandardList (p) {
  return http.get('/unibu/analysis/list', p)
}

export function editStandard (p) {
  return http.post('/unibu/analysis/edit', p)
}

export function delStandard (p) {
  return http.post('/unibu/analysis/del', p)
}
