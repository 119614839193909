import { render, staticRenderFns } from "./Add.vue?vue&type=template&id=f32c2e8a&scoped=true&"
import script from "./Add.vue?vue&type=script&lang=js&"
export * from "./Add.vue?vue&type=script&lang=js&"
import style0 from "./Add.vue?vue&type=style&index=0&id=f32c2e8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f32c2e8a",
  null
  
)

export default component.exports