var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContainer',[_c('BaseTable',{attrs:{"id":"priceStandardConfig","loading":_vm.loading,"data":_vm.tableData},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){_vm.addVisible=true}}},[_vm._v("新 增")])]},proxy:true}])},[_c('vxe-column',{attrs:{"type":"expand","align":"center","width":"80"},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var mps = ref.row.mps;
var data = ref.row;
var rowIndex = ref.rowIndex;
return [_c('BaseTable',{ref:'tableRef'+rowIndex,attrs:{"toolsbar":false,"data":mps,"keep-source":true,"edit-config":{trigger: 'manual', mode: 'row', autoClear: false, }}},[_c('vxe-column',{attrs:{"width":"50"}}),_c('vxe-column',{attrs:{"field":"typeName","title":"材料名称"}}),_c('vxe-column',{attrs:{"field":"type","title":"材料类型"}}),_c('vxe-column',{attrs:{"field":"standard","title":"基准值","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number","min":60,"max":140,"placeholder":"请输入基准值，范围在60到140之间"},model:{value:(row.standard),callback:function ($$v) {_vm.$set(row, "standard", _vm._n($$v))},expression:"row.standard"}})]}}],null,true)}),_c('vxe-column',{attrs:{"title":"操作","show-overflow":false,"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.$refs['tableRef' + rowIndex].isActiveByRow(row))?[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveRowEvent(row, data)}}},[_vm._v("保存")]),_c('el-button',{on:{"click":function($event){return _vm.cancelRowEvent(row, rowIndex)}}},[_vm._v("取消")])]:[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.editRowEvent(row, rowIndex)}}},[_vm._v("编辑")])]]}}],null,true)})],1)]}}])}),_c('vxe-column',{attrs:{"field":"areaName","title":"地区"}}),_c('vxe-column',{attrs:{"field":"id","title":"地区ID"}}),_c('vxe-column',{attrs:{"field":"level","title":"地区级别"}}),_c('vxe-column',{attrs:{"field":"year","title":"年月"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.year)+"-"+_vm._s(row.month)+" ")]}}])}),_c('vxe-column',{attrs:{"title":"操作","show-overflow":false,"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"pt-15 bg-white"}),_c('Add',{attrs:{"visible":_vm.addVisible},on:{"update:visible":function($event){_vm.addVisible=$event},"refresh":_vm.initList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }